const NotFoundTemplate = `
<div class="notfound">
    <p>404 Page</p>
</div>
`

const NotFound = {
    template: NotFoundTemplate,
    created() {},
    beforeMount() {
        this.$root.hideIdle();
    }
}

export { NotFound }