const TableTemplate = `
<section data-module="table" v-if="jsonData.recognition">
   <div data-element="inner">                
        <div class="heading plain_large" v-html="jsonData.recognition.heading"></div>
        <div class="table">
            <div class="table_head">
                <div data-element="grid">
                    <div data-element="grid_item" class="year"><h3><span class="label">Year</span></h3></div>
                    <div data-element="grid_item" class="award"><h3><span class="label">Award</span></h3></div>
                    <div data-element="grid_item" class="type"><h3><span class="label">Type</span></h3></div>
                    <div data-element="grid_item" class="category"><h3><span class="label">Category</span></h3></div>
                    <div data-element="grid_item" class="client"><h3><span class="label">Client</span></h3></div>
                    <div data-element="grid_item" class="project"><h3><span class="label">Project</span></h3></div>
                </div>
            </div>
            <div class="table_body">
                <div data-element="grid" v-for="item in jsonData.recognition.items">
                    <div data-element="grid_item" class="year plain_regular"><span class="label">{{ item.year }}</span></div>
                    <div data-element="grid_item" class="award plain_regular"><span class="label">{{ item.award }}</span></div>
                    <div data-element="grid_item" class="type plain_regular"><span class="label">{{ item.type }}</span></div>
                    <div data-element="grid_item" class="category plain_regular"><span class="label">{{ item.category }}</span></div>
                    <div data-element="grid_item" class="client plain_regular"><span class="label">{{ item.client }}</span></div>
                    <div data-element="grid_item" class="project plain_regular"><span class="label">{{ item.project }}</span></div>
                </div>
            </div>
        </div>
    </div>
</section>
`

const TableModule = {
    template: TableTemplate,
    props: {
        jsonData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            bodyElement: document.body
        } 
    },
    created() {},
    mounted() {},
    beforeUnmount() {}
}

export { TableModule }