// import libraries
//import gsap from './assets/js/gsap.min.js

/*
gsap.ticker.add(gsapTickerFunction);
function gsapTickerFunction() {
    console.log('gsap');
}
*/

// import components
import { Contact } from './components/contact.js'
import { Cursor } from './components/cursor.js'
import { Footer } from './components/footer.js'
import { Grid } from './components/grid.js'
import { Header } from './components/header.js'
import { Idle } from './components/idle.js'

// import views
import { Case } from './views/case.js'
import { Index } from './views/index.js'
import { NotFound } from './views/notfound.js'
import { Studio } from './views/studio.js'

// routes
const routes = [
    { path: '/', name: 'index', component: Index },
    { path: '/studio', name: 'studio', component: Studio },
    { path: '/cases/:handle', name: 'case', component: Case },
    { path: '/:pathMatch(.*)*', name: 'notfound', component: NotFound }
]
const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    //history: createWebHashHistory(), // This has a bad impact on SEO. If that's a concern for you, use the HTML5 history mode.
    //history: VueRouter.createWebHistory('/kuhlandhan-2023'), // use if viewed in subdirectory on server
    history: VueRouter.createWebHistory(), // https://router.vuejs.org/guide/essentials/history-mode#HTML5-Mode
    routes, // short for `routes: routes`
    linkActiveClass: 'current',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    }
})
//router.beforeEach((to, from) => {})

const MainTemplate = `
<page-header></page-header>
<main>
    <router-view v-slot="{ Component, route }">
        <Transition appear name="fade" mode="out-in" :duration="{ enter: 500, leave: 500 }">
            <component :is="Component" :key="route.path" :app-data="appData" />
        </Transition>
    </router-view>
</main>
<idle :app-data="appData"></idle>
<page-footer :app-data="appData"></page-footer>
<contact :app-data="appData"></contact>
<cursor></cursor>
<grid></grid>
`

const app = Vue.createApp({
    components: {
        'page-header': Header,
        'idle': Idle,
        'page-footer': Footer,
        'contact': Contact,
        'cursor': Cursor,
        'grid': Grid
    },
    template: MainTemplate,
    data() {
        return {
            appData: {},
            rootElement: document.documentElement,
            bodyElement: document.body,
            backgroundColor: 'white',
            cursorAnimation: false,
            cursorVisibility: false,
            contactVisibility: false,
            idleImages: {},
            idleId: '',
            idleIndex: 0,
            windowWidth: 0,
            lastKnownMouseXPosition: 0,
            lastKnownMouseYPosition: 0,
            ticking: false
        } 
    },
    watch: {
        appData(newData, oldData) {
            if (newData != oldData) {
                //console.log('newData', newData);
            }
        },
        backgroundColor(newColor, oldColor) {
            if (newColor != oldColor) {
                this.setBackgroundColor( newColor )
            }
        },
        cursorAnimation(newState, oldState) {
            if (newState != oldState) {
                this.setCursorAnimation()
            }
        },
        cursorVisibility(newState, oldState) {
            if (newState != oldState) {
                this.setCursorVisibility()
            }
        },
        contactVisibility(newState, oldState) {
            if (newState != oldState) {
                this.setContactVisibility()
            }
        },
        idleImages(newState, oldState) {
            if (newState != oldState) {
                //console.log('idleImages', newState);
            }
        }
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
        },
        pauseVideo(item) {
            item.pause();
        },
        playVideo(item) {
            //item.play();
            let playPromise = item.play();
			/*
            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {})
                    .catch(error => {});
            }
			*/
        },
        setCursorAnimation() {
            if( this.cursorAnimation ) {
                this.bodyElement.classList.add('animate_cursor');
            } else {
                this.bodyElement.classList.remove('animate_cursor');
            }
        },
        setCursorVisibility() {
            if( this.cursorVisibility ) {
                this.bodyElement.classList.add('show_cursor');
            } else {
                this.bodyElement.classList.remove('show_cursor');
            }
        },
        setContactVisibility() {
            if( this.contactVisibility ) {
                this.cursorAnimation = true;
                this.bodyElement.classList.add('contact_show');
            } else {
                this.cursorAnimation = false;
                this.bodyElement.classList.remove('contact_show');
            }
        },
        setBackgroundColor(color = 'white'){
            this.bodyElement.dataset.background = color;
        },
        handleIdleImage(item) {
            if( !item.classList.contains('loaded') ){
                const src = item.dataset.src;
                item.src = src;
                item.classList.add('loaded');
            }
        },
        showIdle() {
            this.contactVisibility = false;
            if( !this.bodyElement.classList.contains('show_idle') ){
                this.bodyElement.classList.add('show_idle');
                const idle_images = this.bodyElement.querySelectorAll('#idle img');
                idle_images.forEach( this.handleIdleImage );
                idle_images[this.idleIndex].classList.add('show');
                this.idleId = setInterval(() => {
                    idle_images[this.idleIndex].classList.remove('show');
                    this.idleIndex = ((this.idleIndex + 1) < idle_images.length) ? (this.idleIndex + 1) : 0;
                    idle_images[this.idleIndex].classList.add('show');
                }, 200);
            }
        },
        hideIdle() {
            if( this.bodyElement.classList.contains('show_idle') ){
                clearInterval(this.idleId);
                setTimeout(() => {
                    this.bodyElement.classList.remove('show_idle');
                }, 200)
            }

        },
        handleResize(){
            this.updateWindowWidth();
        },
        handleOnLoad(){
            this.bodyElement.classList.add("window_load");
        },
        async getData(){
            const headers = new Headers();
            headers.append(
                "Authorization",
                "api_key"
            );
            const request = new Request(
                //"http://localhost:8888/kuhlandhan-2023/src/appdata/data.json",
                "https://kuhlandhan.com/wp-2023/wp-content/uploads/appdata/data.json",
                {
                    method: "GET",
                    headers,
                    mode: "cors",
                    cache: "no-cache", // "no-cache" for production, set to "default" when live
                    credentials: "same-origin",
                    referrerPolicy: "no-referrer"
                }
            ); 
            const response = await fetch(request);
            //console.log(response);
            const data = await response.json();
            this.appData = data;
        },
        handleOnMouseover(event){
            const target = event.target;
            const slideshow = target.closest('.slideshow');
            const videoplayer = target.closest('.video[data-type="player"]');
            const pagelink = target.closest('a');
            if( slideshow ){
                this.cursorAnimation = true;
                this.cursorVisibility = true;
            }
            if( videoplayer ){
                this.cursorVisibility = true;
                if( videoplayer.classList.contains('video_playing') ){
                    this.cursorAnimation = true;
                }
            }
            if( pagelink ){
                this.cursorVisibility = true;
                if( pagelink.closest('.active') ){
                    this.cursorAnimation = true;
                }
                if( this.windowWidth > 760 && pagelink.dataset.pagelink && pagelink.dataset.pagelink == 'logo') {
                    this.showIdle();
                }
                
                const media_files = pagelink.querySelector('.media_files');
                if( this.windowWidth > 1440 && media_files ){
                    if( !media_files.classList.contains('all_loaded') ){
                        const media_file_array = media_files.querySelectorAll('.media_file');                    
                        const media_file_array_length = media_file_array.length;
                        let horisontal_positions = ['left', 'center', 'right'];
                        let vertical_positions = ['top', 'middle', 'bottom'];
                        this.$root.shuffleArray(horisontal_positions);
                        this.$root.shuffleArray(vertical_positions);
                        media_file_array.forEach((item, index) => {
                            const media_element = item.querySelector(':first-child');
                            const data_src = media_element.dataset.src;
                            const type = media_element.nodeName;
                            //console.log((index + 1), media_file_array_length, type, data_src);
                            const horisontal_position = horisontal_positions.shift();
                            const vertical_position = vertical_positions.shift();
                            item.classList.add(horisontal_position);
                            item.classList.add(vertical_position);
                            //console.log(horisontal_position);
                            //console.log(vertical_position);
                            if( type == 'VIDEO' ){
                                media_element.setAttribute('autoplay','');
                                media_element.onloadeddata = () => {
                                    //console.log('canplay');
                                    if( (index + 1) == media_file_array_length ){
                                        media_files.classList.add('all_loaded');
                                    }
                                    this.playVideo(media_element);
                                };
                            } else if( type == 'IMG' ){
                                media_element.onload = () => {
                                    //console.log('load');
                                    if( (index + 1) == media_file_array_length ){
                                        media_files.classList.add('all_loaded');
                                    }
                                };
                            }
                            media_element.src = data_src;
                        });
                    } else {
                        // get all video elements
                        //const video_elements = pagelink.querySelectorAll('.media_files .media_file video');
                        //video_elements.forEach(this.playVideo);
                    }                    
                }
                
            }
        },
        handleOnMouseout(event){
            const target = event.target;
            const slideshow = target.closest('.slideshow');
            const videoplayer = target.closest('.video[data-type="player"]');
            const pagelink = target.closest('a');
            if( slideshow ){
                this.cursorAnimation = false;
                this.cursorVisibility = false;
            }
            if( videoplayer ){
                this.cursorVisibility = false;
                if( videoplayer.classList.contains('video_playing') ){
                    this.cursorAnimation = false;
                }
            }
            if( pagelink ){
                this.cursorVisibility = false;
                if( pagelink.closest('.active') ){
                    this.cursorAnimation = false;
                }
                if( this.windowWidth > 760 && pagelink.dataset.pagelink && pagelink.dataset.pagelink == 'logo') {
                    this.hideIdle();
                }
                /*
                const media_files = pagelink.querySelector('.media_files');
                if( this.windowWidth > 1440 && media_files ){
                    // get all video elements and pause them
                    const video_elements = media_files.querySelectorAll('.media_file video');
                    video_elements.forEach(this.pauseVideo);
                }
                */
            }
        },
        setMousePositions(mouseXPos, mouseYPos) {
            this.rootElement.style.setProperty("--client_x", mouseXPos + "px");
            this.rootElement.style.setProperty("--client_y", mouseYPos + "px");
        },
        handleOnMouseMove(event){
            this.lastKnownMouseXPosition = event.clientX;
            this.lastKnownMouseYPosition = event.clientY;
        
            if (!this.ticking) {
                window.requestAnimationFrame(() => {
                    this.setMousePositions(this.lastKnownMouseXPosition, this.lastKnownMouseYPosition);
                    this.ticking = false;
                });
                this.ticking = true;
            }
        }
    },
    created() {
        this.backgroundColor = 'white';
        this.updateWindowWidth();
    },
    beforeMount() {},
    mounted() {
        this.getData();
        window.addEventListener("load", this.handleOnLoad);
        window.addEventListener("resize", this.handleResize);        
        window.addEventListener("mouseover", this.handleOnMouseover);
        window.addEventListener("mouseout", this.handleOnMouseout);
        window.addEventListener("mousemove", this.handleOnMouseMove);
    },
    beforeUnmount() {
        window.removeEventListener("load", this.handleOnLoad);
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("mouseover", this.handleOnMouseover);
        window.removeEventListener("mouseout", this.handleOnMouseout);
        window.removeEventListener("mousemove", this.handleOnMouseMove);
    }
})
app.use(router)
app.config.errorHandler = (err) => {
    alert(err);
}
app.mount('#app')
