const CursorTemplate = `
<div id="cursor">
    <div class="circles"></div>
</div>
`

const Cursor = {
    template: CursorTemplate
}

export { Cursor }