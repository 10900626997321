const CreditsTemplate = `
<section data-module="credits" v-if="caseData">
    <div data-element="inner">
        <div class="table">
            <div class="table_head">                
                <div data-element="grid">
                    <div data-element="grid_item" class="project"><h3><span class="label">Project</span></h3></div>
                </div>
            </div>
            <div class="table_body">
                <div data-element="grid">
                    <div data-element="grid_item" class="project"><span class="label">Credits</span></div>
                </div>                
            </div>
        </div>
        <div class="text">
            <div data-element="grid">
                <div data-element="grid_item" class="copy plain_regular" v-html="caseData.credits"></div>
            </div>
        </div>
    </div>
</section>
`

const CreditsSection = {
    template: CreditsTemplate,
    props: {
        caseData: {
            type: Object,
            required: true
        }
    }
}

export { CreditsSection }