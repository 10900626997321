// import modules
import { TextModule } from '../components/text.js'
import { ColumnsModule } from '../components/columns.js'
import { TableModule } from '../components/table.js'

const StudioTemplate = `
<div class="studio">
    <text-module :json-data="appData"></text-module>
    <columns-module :json-data="appData"></columns-module>
    <table-module :json-data="appData"></table-module>
</div>
`

const Studio = {
    components: {
        'text-module': TextModule,
        'columns-module': ColumnsModule,
        'table-module': TableModule
    },
    template: StudioTemplate,
    props: {
        appData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            bodyElement: document.body
        } 
    },
    methods: {},
    created() {},
    beforeMount() {
        this.$root.hideIdle();
    },
    mounted() {
        this.$root.backgroundColor = 'brown';
        this.$root.contactVisibility = false;
    },
    beforeUnmount() {
        this.$root.cursorVisibility = false;
    }
}

export { Studio }