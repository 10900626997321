const TextTemplate = `
<section data-module="text" v-if="jsonData.studio">
    <div data-element="inner">
        <div class="heading plain_extrasmall" v-html="jsonData.studio.heading"></div>
        <div class="copy plain_extralarge" v-html="jsonData.studio.copy"></div>
    </div>
</section>
`

const TextModule = {
    template: TextTemplate,
    props: {
        jsonData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            bodyElement: document.body
        } 
    },
    created() {},
    mounted() {},
    beforeUnmount() {}
}

export { TextModule }