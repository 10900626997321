const FooterTemplate = `
<footer>
    <div data-element="inner">
        <div data-element="grid">
            <div data-element="grid_item" class="address" v-if="appData.address">
                <div class="copy">
                    <p><a v-bind:href="appData.address.map_url" class="url" target="_blank"><span>{{ appData.address.company }}</span><span>{{ appData.address.street }}</span><span>{{ appData.address.zip }}, {{ appData.address.city }}</span><span>{{ appData.address.country }}</span></a></p>
                </div>
            </div>
            <div data-element="grid_item" class="email" v-if="appData.contact">
                <div class="copy">
                    <p><a v-bind:href="buildMailto" class="mailto"><span>{{ appData.contact.email_username }}{{ appData.contact.email_symbol }}</span><span>{{ appData.contact.email_domainname }}</span><span>{{ appData.contact.email_domaintld }}</span></a></p>
                </div>
            </div>
            <div data-element="grid_item" class="phone" v-if="appData.contact">
                <div class="copy">
                    <p><a v-bind:href="buildTel" class="tel"><span>{{ appData.contact.phone_country }}</span><span>{{ appData.contact.phone_part_1 }}</span><span>{{ appData.contact.phone_part_2 }}</span><span>{{ appData.contact.phone_part_3 }}</span><span>{{ appData.contact.phone_part_4 }}</span></a></p>
                </div>
            </div>
            <div data-element="grid_item" class="social" v-if="appData.social">
                <div class="list">
                    <ul v-if="appData.social">
                        <li v-for="item in appData.social.items"><a v-bind:href="item.url" target="_blank">{{ item.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div data-element="grid_item" class="about" v-if="appData.about">
                <div class="copy" v-html="appData.about.copy"></div>
            </div>
            <div data-element="grid_item" class="copyright" v-if="appData.copyright">
                <div class="copy">
                    <p>&copy; {{ appData.copyright.year }} {{ appData.address.name }}</p>
                </div>
            </div>
        </div>
    </div>
</footer>
`

const Footer = {
    template: FooterTemplate,
    props: {
        appData: {
            type: Object,
            required: true
        }
    },
    computed: {
        // computed property that auto-updates when the prop changes
        buildMailto() {
            return 'mailto:' + this.appData.contact.email_username + this.appData.contact.email_symbol + this.appData.contact.email_domainname + this.appData.contact.email_domaintld;
        },
        // computed property that auto-updates when the prop changes
        buildTel() {
            return 'tel:' + this.appData.contact.phone_country_code + this.appData.contact.phone_part_1 + this.appData.contact.phone_part_2 + this.appData.contact.phone_part_3 + this.appData.contact.phone_part_4;
        }
    }
}

export { Footer }