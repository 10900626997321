const HeaderTemplate = `
<header ref="header">
    <div data-element="inner">
        <div data-element="grid">
            <div data-element="grid_item" class="logo">
                <div class="image">
                    <router-link :to="{ name: 'index', params: {} }" class="plain_regular" data-pagelink="logo" title="Index"><img src="https://kuhlandhan.com/kuhlandhan-2023/src/assets/logo/logo-black-200x32.svg" width="200" height="32" alt="Kühl & Han - Logo" loading="eager" class="responsive"></router-link>
                </div>
            </div>
            <div data-element="grid_item" class="navigation">
                <div class="list">
                    <ul>
                        <li><router-link :to="{ name: 'index', params: {} }" class="plain_regular" data-pagelink title="Index"><span class="label">Index</span></router-link></li>
                        <li><router-link :to="{ name: 'studio', params: {} }" class="plain_regular" data-pagelink title="Studio"><span class="label">Studio</span></router-link></li>
                    </ul>
                </div>
            </div>
            <div data-element="grid_item" class="contact">
                <div class="list">
                    <ul>
                        <li :class="{ active: this.$root.contactVisibility }"><a href="#" class="plain_regular" data-pagelink @click.prevent="this.$root.contactVisibility = !this.$root.contactVisibility"><span class="label">Contact</span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
` 

const Header = {
    template: HeaderTemplate,
    methods: {},
    mounted() {},
    beforeUnmount() {}
}

export { Header }