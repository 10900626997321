const InformationTemplate = `
<section data-module="information" v-if="caseData">
    <div data-element="inner">
        <div class="table">
            <div class="table_head">                
                <div data-element="grid">
                    <div data-element="grid_item" class="client"><h3><span class="label">Client</span></h3></div>
                    <div data-element="grid_item" class="project"><h3><span class="label">Project</span></h3></div>
                    <div data-element="grid_item" class="year"><h3><span class="label">Year</span></h3></div>
                </div>
            </div>
            <div class="table_body">
                <div data-element="grid">
                    <div data-element="grid_item" class="client"><span class="label">{{ caseData.client }}</span></div>
                    <div data-element="grid_item" class="project"><span class="label">{{ caseData.project }}</span></div>
                    <div data-element="grid_item" class="year"><span class="label">{{ caseData.year }}</span></div>
                </div>                
            </div>
        </div>
        <div class="text">
            <div data-element="grid">
                <div data-element="grid_item" class="copy plain_regular" v-html="caseData.description"></div>
            </div>
        </div>
    </div>
</section>
`

const InformationSection = {
    template: InformationTemplate,
    props: {
        caseData: {
            type: Object,
            required: true
        }
    }
}

export { InformationSection }