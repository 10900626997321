const IdleTemplate = `
<div id="idle" v-if="appData.idleimages" ref="idle">
    <img v-bind:data-src="item" v-for="item in appData.idleimages" ref="img" alt="Kühl & Han">
</div>
`

const Idle = {
    template: IdleTemplate,    
    props: {
        appData: {
            type: Object,
            required: true
        }
    }
}

export { Idle }