// import modules
import { InformationSection } from '../components/information.js'
import { ModulesSection } from '../components/modules.js'
import { CreditsSection } from '../components/credits.js'

const CaseTemplate = `
<div class="case" v-if="appData.cases">
    <information-section :case-data="caseData"></information-section>
    <modules-section :case-data="caseData"></modules-section>
    <credits-section :case-data="caseData"></credits-section>
</div>
`

const Case = {
    components: {
        'information-section': InformationSection,
        'modules-section': ModulesSection,
        'credits-section': CreditsSection
    },
    template: CaseTemplate,
    props: {
        appData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            bodyElement: document.body
        } 
    },
    methods: {},
    computed: {
        // computed property that auto-updates when the prop changes
        caseData() {
            
            // get cases
            const cases = this.appData.cases;
            
            // get route handle
            const case_handle = this.$route.params.handle;
            
            // find case by route handle
            const case_data = cases.find((element) => element.handle === case_handle);
            
            // get case data
            const case_client = case_data.client;
            const case_project = case_data.project;
            const case_year = case_data.year;
            const case_description = case_data.description;
            const case_modules = case_data.modules;
            const case_credits = case_data.credits;
            
            // build case data object
            const caseData = {
                client: case_client,
                project: case_project,
                year: case_year,
                description: case_description,
                modules: case_modules,
                credits: case_credits
            };
            
            return caseData;
        }
    },
    created() {},    
    beforeMount() {
        this.$root.hideIdle();
    },
    mounted() {
        this.$root.backgroundColor = 'white';
        this.$root.contactVisibility = false;
    },
    beforeUnmount() {
        this.$root.cursorVisibility = false;
    }
}

export { Case }