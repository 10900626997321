const GridTemplate = `
<div id="grid">
    <div data-element="inner">
        <div data-element="grid">
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
            <div data-element="grid_item"></div>
        </div>
    </div>
</div>
`

const Grid = {
    template: GridTemplate,
    data() {
        return {
            bodyElement: document.body
        } 
    },
    mounted() {
        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.keyCode === 71) {
                this.bodyElement.classList.toggle("show_grid");
                return;
            }
        });
    }
}

export { Grid }