// import libraries
import LazyLoad from '../assets/js/lazyload.esm.js'
import Splide from '../assets/js/splide.esm.js'

const ModulesTemplate = `
<div class="modules" v-if="caseData.modules && caseData.modules.length > 0" ref="modules">
    <div class="module" v-for="module in caseData.modules" v-bind:class="module.data_module" :key="module">

        <section data-module="video" v-bind:class="module.data_width" v-if="module.data_module === 'video' && module.data_type === 'loop'" ref="video">
            <div class="video" data-type="loop">
				<div v-if="module.data_width === 'full'">
					<div class="video_wrapper">
                		<video class="lazy" v-bind:data-src="module.src" preload="none" v-bind:width="module.width" v-bind:height="module.height" v-bind:data-poster="module.poster" muted loop playsinline disableremoteplayback></video>
					</div>
				</div>
                <div data-element="inner" v-else-if="module.data_width === 'medium'">
                    <div data-element="grid">
                        <div data-element="grid_item" data-width="medium">
							<div class="video_wrapper">
                            	<video v-bind:style="{ 'aspect-ratio': module.width/module.height }" class="lazy" v-bind:data-src="module.src" preload="none" v-bind:width="module.width" v-bind:height="module.height" v-bind:data-poster="module.poster" muted loop playsinline disableremoteplayback></video>
							</div>
                        </div>
                    </div>
                </div>
                <div data-element="inner" v-else-if="module.data_width === 'small'">
                    <div data-element="grid">
                        <div data-element="grid_item" data-width="small" v-bind:data-position="module.data_position">
                            <div class="video_wrapper">
								<video v-bind:style="{ 'aspect-ratio': module.width/module.height }" class="lazy" v-bind:data-src="module.src" preload="none" v-bind:width="module.width" v-bind:height="module.height" v-bind:data-poster="module.poster" muted loop playsinline disableremoteplayback></video>
							</div>
                        </div>
                    </div>
                </div>
            </div>            
            <div class="caption" v-if="module.copy">
                <div data-element="inner">
                    <div data-element="grid">
                        <div data-element="grid_item">
                            <div class="copy" v-html="module.copy"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section data-module="video" v-bind:class="module.data_width" v-else-if="module.data_module === 'video' && module.data_type === 'player'" ref="video">
            <div class="video" data-type="player" data-sound="off">
                <div v-if="module.data_width === 'full'">
                    <div class="video_wrapper">
						<video v-bind:style="{ 'aspect-ratio': module.width/module.height }" class="lazy" v-bind:data-src="module.src" preload="none" v-bind:width="module.width" v-bind:height="module.height" v-bind:data-poster="module.poster" muted data-inview disableremoteplayback></video>
					</div>
                    <div class="controls">
                        <div data-element="inner">
                            <div data-element="grid">
                                <div data-element="grid_item" class="play"><a href="#" class="play_toggle"><span class="label">Play</span><span class="label">Pause</span></a></div>
                                <div data-element="grid_item" class="duration"><span class="label">00:00:00</span></div>
                                <div data-element="grid_item" class="buttons">
                                    <ul>
                                        <li><a href="#" class="sound_toggle"><span class="label sound_off">Sound off</span><span class="label sound_on">Sound on</span></a></li>
                                        <li><a href="#" class="fullscreen_toggle"><span class="label">Fullscreen</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="scrubber">
                        <div data-element="inner">
                            <div class="track">
								<div class="progress"></div>
							</div>
                        </div>
                    </div>
                </div>
                <div data-element="inner" v-else-if="module.data_width === 'medium'">
                    <div data-element="grid">
                        <div data-element="grid_item" data-width="medium">
                            <div class="video_wrapper">
								<video v-bind:style="{ 'aspect-ratio': module.width/module.height }" class="lazy" v-bind:data-src="module.src" preload="none" v-bind:width="module.width" v-bind:height="module.height" v-bind:data-poster="module.poster" muted data-inview disableremoteplayback></video>
							</div>
                            <div class="controls">
                                <div data-element="inner">
                                    <div data-element="grid">
                                        <div data-element="grid_item" class="play"><a href="#" class="play_toggle"><span class="label">Play</span><span class="label">Pause</span></a></div>
                                        <div data-element="grid_item" class="duration"><span class="label">00:00:00</span></div>
                                        <div data-element="grid_item" class="buttons">
                                            <ul>
                                                <li><a href="#" class="sound_toggle"><span class="label sound_off">Sound off</span><span class="label sound_on">Sound on</span></a></li>
                                                <li><a href="#" class="fullscreen_toggle"><span class="label">Fullscreen</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="scrubber">
								<div data-element="inner">
									<div class="track">
										<div class="progress"></div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                </div>
                <div data-element="inner" v-else-if="module.data_width === 'small'">
                    <div data-element="grid">
                        <div data-element="grid_item" data-width="small">
                            <div class="video_wrapper">
								<video v-bind:style="{ 'aspect-ratio': module.width/module.height }" class="lazy" v-bind:data-src="module.src" preload="none" v-bind:width="module.width" v-bind:height="module.height" v-bind:data-poster="module.poster" muted data-inview disableremoteplayback></video>
							</div>
                            <div class="controls">
                                <div data-element="inner">
                                    <div data-element="grid">
                                        <div data-element="grid_item" class="play"><a href="#" class="play_toggle"><span class="label">Play</span><span class="label">Pause</span></a></div>
                                        <div data-element="grid_item" class="duration"><span class="label">00:00:00</span></div>
                                        <div data-element="grid_item" class="buttons">
                                            <ul>
                                                <li><a href="#" class="sound_toggle"><span class="label sound_off">Sound off</span><span class="label sound_on">Sound on</span></a></li>
                                                <li><a href="#" class="fullscreen_toggle"><span class="label">Fullscreen</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="scrubber">
								<div data-element="inner">
									<div class="track">
										<div class="progress"></div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="caption" v-if="module.copy">
                <div data-element="inner">
                    <div data-element="grid">
                        <div data-element="grid_item">
                            <div class="copy" v-html="module.copy"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-module="image" v-if="module.data_module === 'image' && module.data_width !== 'full'" v-bind:data-width="module.data_width" v-bind:data-alignment="module.data_alignment">
            <div class="image">
                <div data-element="inner">
                    <div data-element="grid">
                        <div data-element="grid_item">
                            <picture>
                                <source media="(min-width: 1024px)" v-bind:data-srcset="module.desktop_src">
                                <img v-bind:style="{ 'aspect-ratio': module.mobile_width/module.mobile_height }" v-bind:data-src="module.mobile_src" v-bind:width="module.mobile_width" v-bind:height="module.mobile_height" alt="Kühl & Han" loading="lazy" class="lazy responsive">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
            <div class="caption" v-if="module.copy">
                <div data-element="inner">
                    <div data-element="grid">
                        <div data-element="grid_item">
                            <div class="copy" v-html="module.copy"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section data-module="image" v-else-if="module.data_module === 'image' && module.data_width === 'full'" v-bind:data-width="module.data_width" v-bind:data-alignment="module.data_alignment">
            <div class="image">
                <picture>
                    <source media="(min-width: 1024px)" v-bind:data-srcset="module.desktop_src">
                    <img v-bind:style="{ 'aspect-ratio': module.mobile_width/module.mobile_height }" v-bind:data-src="module.mobile_src" v-bind:width="module.mobile_width" v-bind:height="module.mobile_height" alt="Kühl & Han" loading="lazy" class="lazy responsive">
                </picture>
            </div>
            <div class="caption" v-if="module.copy">
                <div data-element="inner">
                    <div data-element="grid">
                        <div data-element="grid_item">
                            <div class="copy" v-html="module.copy"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-module="gallery" v-if="module.data_module === 'gallery'">
            <div data-element="inner">
                <div data-element="grid" class="images" v-bind:class="{ images_reverse: module.reverse_order }">
                    <div data-element="grid_item" class="image" v-for="(item, index) in module.items" v-bind:class="{ image_margin: index == 0 && module.push_down === 'first' || index == 1 && module.push_down === 'second' }">
                        <picture v-if="item.class === 'image'">
                            <source media="(min-width: 1024px)" v-bind:data-srcset="item.desktop_src">
                            <img v-bind:style="{ 'aspect-ratio': module.mobile_width/module.mobile_height }" v-bind:data-src="item.mobile_src" v-bind:width="item.mobile_width" v-bind:height="item.mobile_height" alt="Kühl & Han" loading="lazy" class="lazy responsive">
                        </picture>
                        <div v-else-if="item.class === 'video'" class="video_wrapper" ref="video">
                            <div class="video" data-type="loop">
                                <video v-bind:style="{ 'aspect-ratio': item.width/item.height }" class="lazy" v-bind:data-src="item.src" preload="none" v-bind:width="item.width" v-bind:height="item.height" v-bind:data-poster="module.poster" muted loop playsinline disableremoteplayback></video>
                            </div>
                        </div>
                        <div class="copy" v-if="item.copy" v-html="item.copy"></div>
                    </div>                    
                </div>
            </div>
        </section>

        <section data-module="slideshow" v-if="module.data_module === 'slideshow'" ref="slideshow">
            <div class="slideshow">
                <div class="splide" aria-label="Slideshow">
                    <div class="splide__track">
                        <div class="splide__list">
                            <div class="splide__slide" v-for="(image, index) in module.images" v-bind:class="image.orientation">                            
                                <div v-bind:class="image.class">
                                    <picture>
                                        <source media="(min-width: 1024px)" v-bind:data-srcset="image.desktop_src">
                                        <img v-bind:style="{ 'aspect-ratio': module.mobile_width/module.mobile_height }" v-bind:data-src="image.mobile_src" v-bind:width="image.mobile_width" v-bind:height="image.mobile_height" v-bind:alt="image.alt" loading="lazy" class="lazy responsive">
                                    </picture>
                                </div>
                                <div class="caption" v-if="image.copy">                    
                                    <div class="copy" v-html="image.copy"></div>                            
                                    <div class="number">
                                        <p>{{ index + 1 }}</p>
                                    </div>
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>
`

const ModulesSection = {
    template: ModulesTemplate,
    props: {
        caseData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            bodyElement: document.body
        } 
    },
    methods: {
        initInview() {
            const inview_observer_callback = (items) => {
                items.forEach((item) => {
                    const target = item.target;
                    if (item.isIntersecting) {
                        target.classList.add('inview');
                    } else {
                        target.classList.remove('inview');
                        if( target.nodeName == 'VIDEO' && !target.paused ){
                            target.pause();
                        }
                    }
                });
            };
            const inview_observer_options = {
                root: null, // Defaults to the browser viewport if not specified or if null.
                rootMargin: '0px 0px 0px 0px', // top, right, bottom, left
                threshold: 0.4
            };
            const inview_observer = new IntersectionObserver(
                inview_observer_callback,
                inview_observer_options
            );
            const inview_elements = document.querySelectorAll('[data-inview]');
            inview_elements.forEach((inview_element) => {
                inview_observer.observe(inview_element);
            });
        },
        initVideoModule(item) {

            const video_parent = item.querySelector('.video');
            const video_type = video_parent.dataset.type;
            const video_element = video_parent.querySelector('video');

            if( video_parent && video_type && video_element){

                if( video_type === 'loop' ){

                    video_element.oncanplay = () => {
                        // The canplay event is fired when the user agent can play the media, but estimates that not enough data has been loaded to play the media up to its end without having to stop for further buffering of content.
                        //console.log('canplay');
                        video_element.play();
                        video_parent.classList.add('video_canplay');
                    }

                } else if( video_type === 'player' ){

                    const play_toggle_element = video_parent.querySelector('.play_toggle');
                    const duration_element = video_parent.querySelector('.duration .label');
                    const sound_toggle_element = video_parent.querySelector('.sound_toggle');
                    const fullscreen_toggle_element = video_parent.querySelector('.fullscreen_toggle');
                    const scrubber_element = video_parent.querySelector('.scrubber');
                    const scrubber_track_element = video_parent.querySelector('.scrubber .track');
                    let idleTimer = null;
                    let idleState = false;
                    
                    function hideControls(time) {
                        //console.log('hideControls', time);
                        clearTimeout(idleTimer);
                        if (idleState == true) {
                            video_parent.classList.remove('idle');
                        }
                        idleState = false;
                        idleTimer = setTimeout(function() {
                            video_parent.classList.add('idle');
                            idleState = true;
                        }, time);
                    }

                    video_element.onclick = (event) => {
                        event.preventDefault();
                        const target = event.target;
                        if (target.paused || target.ended) {
                            target.play();
                        } else {
                            target.pause();
                        }            
                    }

                    play_toggle_element.onclick = (event) => {
                        event.preventDefault();
                        //const target = event.target;
                        if (video_element.paused || video_element.ended) {
                            video_element.play();
                        } else {
                            video_element.pause();
                        }            
                    }

                    fullscreen_toggle_element.onclick = (event) => {
                        event.preventDefault();
                        //const target = event.target;   
                        if (document.fullscreenElement) {
                            document.exitFullscreen();
                        } else if (document.webkitFullscreenElement) {
                            // Need this to support Safari
                            document.webkitExitFullscreen();
                        } else if (video_element.webkitRequestFullscreen) {
                            // Need this to support Safari
                            video_element.webkitRequestFullscreen();
                        } else {
                            video_element.requestFullscreen();
                        }
                    }

                    sound_toggle_element.onclick = (event) => {
                        event.preventDefault();
                        //const target = event.target;   
                        if (video_element.muted) {
                            video_element.muted = false;
                        } else {
                            video_element.muted = true;
                        }
                    }

					if(scrubber_track_element){
						scrubber_track_element.onclick = (event) => {
							event.preventDefault();

							// Get the target
							const target = event.target;

							// Get the bounding rectangle of target
							const rect = target.getBoundingClientRect();

							// Mouse position
							const x = event.clientX - rect.left;

							video_element.currentTime = (video_element.duration * (x / rect.width));
						}
					}

                    video_element.ontimeupdate = (event) => {
                        // The timeupdate event is fired when the time indicated by the currentTime attribute has been updated.
                        const target = event.target;
                        const duration = Math.round(target.duration);
                        if( !isNaN(duration) ){
                            const time_played = Math.round(target.currentTime);
                            const time_remaining = (duration - time_played);
                            const result = new Date(time_remaining * 1000).toISOString().slice(11, 19);            
                            duration_element.innerHTML = result;
							
							if(scrubber_element){
								const progress = ((target.currentTime/target.duration) * 100);
								//scrubber_element.dataset.progress = progress;
								scrubber_element.style.setProperty('--progress', progress);
							}
                        }
                    }

                    video_element.onplay = () => {
                        //const target = event.target;
                        //console.log( 'onplay: ' + target );
                        video_parent.classList.add('video_playing');
                        this.$root.cursorAnimation = true;
                        hideControls(2000);
                    };

                    video_element.onloadedmetadata = (event) => {
                        // The loadedmetadata event is fired when the metadata (duration and dimensions of the media) has been loaded.
                        const target = event.target;
                        const duration = Math.round(target.duration);            
                        const result = new Date(duration * 1000).toISOString().slice(11, 19);            
                        duration_element.innerHTML = result;
						
						video_parent.classList.add('scrubber_show');
                    }

                    video_element.onpause = () => {
                        // The pause event is sent when a request to pause an activity is handled and the activity has entered its paused state, most commonly after the media has been paused through a call to the element's pause() method.
                        //const target = event.target;
                        //console.log( 'onpause: ' + target );
                        //console.log( 'onpause' );
                        video_parent.classList.remove('video_playing', 'idle');
                        this.$root.cursorAnimation = false;

                    };

                    video_element.onended = (event) => {
                        // The ended event is fired when playback or streaming has stopped because the end of the media was reached or because no further data is available.
                        const target = event.target;
                        //console.log( 'onended: ' + target );
                        target.currentTime = 0;
                    };        

                    video_element.oncanplay = () => {
                        // The canplay event is fired when the user agent can play the media, but estimates that not enough data has been loaded to play the media up to its end without having to stop for further buffering of content.
                        //video_element.play();
                        //console.log('canplay');
                        video_parent.classList.add('video_canplay');
                    }

                    video_element.onvolumechange = () => {
                        // The volumechange event is fired when the volume has changed.
                        //const target = event.target;
                        //console.log( 'onvolumechange: ' + target );
                        if (video_element.muted) {
                            video_parent.dataset.sound = 'off';
                        } else {
                            video_parent.dataset.sound = 'on';
                        }
                    };

                    video_element.onmousemove = () => {
                        //console.log('video_element.onmousemove');
                        if( video_parent.classList.contains('video_playing') ){
                            hideControls(2000);      
                        }
                    };

                }
                
                // load the video source
                video_element.load();
            }
        },
        initVideoModules() {
            const $elements = this.$refs.video;
            if( $elements !== undefined ){
                $elements.forEach(this.initVideoModule);
            }
            
        },
        initSlideshowModule(item) {
            const splide_element = item.querySelector('.splide');
            if (splide_element) {
                const splide = new Splide(splide_element, {
                    type: 'slide',
                    autoWidth: true,
                    autoplay: false,
                    speed: 500,
                    easing: 'ease',
                    arrows: false,
                    pagination: false
                });
                splide.mount();
            }
        },
        initSlideshowModules() {
            const $elements = this.$refs.slideshow;
            if( $elements !== undefined ){
                $elements.forEach(this.initSlideshowModule);
            }
        },
        initLazyload() {
            //console.log('initLazyload');
            let lazyLoadInstance = new LazyLoad();
        }
    },
    computed: {},
    created() {},
    mounted() {
        this.initInview();
        this.initVideoModules();
        this.initSlideshowModules();
        this.initLazyload();
    },
    beforeUnmount() {}
}

export { ModulesSection }