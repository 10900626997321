const IndexTemplate = `
<div class="index" ref="cases">
    <section data-module="index" v-if="appData.cases">
        <div data-element="inner">
            <div class="table">
                <div class="table_head">
                    <div data-element="grid">
                        <div data-element="grid_item" class="client">
                            <h3><span class="label">Client</span></h3>
                        </div>
                        <div data-element="grid_item" class="project">
                            <h3><span class="label">Project</span></h3>
                        </div>
                        <div data-element="grid_item" class="month">
                            <h3><span class="label">Month</span></h3>
                        </div>
                        <div data-element="grid_item" class="year">
                            <h3><span class="label">Year</span></h3>
                        </div>
                    </div>
                </div>
                <div class="table_body">

                    <div class="case" v-for="(item, index) in appData.cases">
                        <router-link v-if="item.show" :to="{ name: 'case', params: { handle: item.handle } }" data-pagelink>
                            <div class="information">
                                <div data-element="grid">
                                    <div data-element="grid_item" class="client"><span class="label">{{ item.client }}</span></div>
                                    <div data-element="grid_item" class="project"><span class="label">{{ item.project }}</span></div>
                                    <div data-element="grid_item" class="month"><span class="label">{{ item.month }}</span></div>
                                    <div data-element="grid_item" class="year"><span class="label">{{ item.year }}</span></div>
                                </div>
                            </div>
                            <div class="media_files" v-if="item.media_files && item.media_files.length > 0">
                                <div data-element="flex">
                                    <div v-for="media_file in item.media_files" data-element="flex_item" class="media_file" v-bind:data-type="media_file.data_type" v-bind:data-width="media_file.data_width">
                                        <video v-if="media_file.data_type == 'video'" v-bind:data-src="media_file.src" v-bind:width="media_file.width" v-bind:height="media_file.height" class="responsive" loop muted playsinline preload="none"></video>
                                        <img v-if="media_file.data_type == 'img'" v-bind:data-src="media_file.src" v-bind:width="media_file.width" v-bind:height="media_file.height" class="responsive">
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>              

                </div>
            </div>
        </div>
    </section>
</div>
`

const Index = {
    template: IndexTemplate,
    props: {
        appData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            testObject: {}
        } 
    },
    methods: {},
    computed: {},
    created() {},
    beforeMount() {
        this.$root.hideIdle();
    },
    mounted() {
        this.$root.backgroundColor = 'white';
        this.$root.contactVisibility = false;   
    },
    beforeUnmount() {
        this.$root.cursorVisibility = false;
    }
}

export { Index }